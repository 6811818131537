/*
- Custom plugin for indenting a paragraph
-- tab to indent
-- shift tab to deindent  
***/
import _forEach from 'lodash/forEach';
import store from '@/store';

(() => {
	'use strict';

	let $listItem = CKEDITOR.dtd.$listItem;
	let $list = CKEDITOR.dtd.$list;
	
	let isInBlockquote = false; 

	CKEDITOR.plugins.add('indentParagraph', {
		requires: 'indent',
		init(editor) {
			let globalHelpers = CKEDITOR.plugins.indent;

			// Register commands.
			globalHelpers.registerCommands( editor, {
				indentblock: new commandDefinition( editor, 'indentblock', true ),
				outdentblock: new commandDefinition( editor, 'outdentblock' )
			});
			
			// Set up key stroke commands
			editor.on('key', (ev) => {
				if (ev.data.keyCode == 9) {
					// TAB
					let sel = editor.getSelection();
					let path  = new CKEDITOR.dom.elementPath( sel.getCommonAncestor(), sel.root );
					let $paragraphEl = path.contains({p: true}, true);

					if($paragraphEl){
						ev.cancel();
						if(store.state.paperEdit.isCitationContextMenuOpen){
							// Citation Context Menu focus the first item
							window.$vm.emitter.emit('focusCitationContextMenu');

						} else {
							// Normal indent checks
							if(isInBlockquote){
								if($paragraphEl.hasClass('blockquote-indent')){
									window.$vm.emitter.emit('globalToasterOpen',{
										textContent: 'This paragraph cannot be indented further',
										variant: 'danger',
									});
								} else {
									editor.execCommand('indent');
								}
							} else {
								if($paragraphEl.hasClass('no-indent')){
									editor.execCommand('indent');
								} else {
									window.$vm.emitter.emit('globalToasterOpen',{
										textContent: 'This paragraph cannot be indented further',
										variant: 'danger',
									});
								}
							}
						}
					}

				} else if (ev.data.keyCode == (CKEDITOR.SHIFT + 9)) {
					// SHIFT + TAB
					let sel = editor.getSelection();
					let path = new CKEDITOR.dom.elementPath( sel.getCommonAncestor(), sel.root );
					let $paragraphEl = path.contains({p: true}, true);

					if($paragraphEl){
						ev.cancel();
						if(isInBlockquote){
							if($paragraphEl.hasClass('blockquote-indent')){
								editor.execCommand('outdent');
							} else {
								window.$vm.emitter.emit('globalToasterOpen',{
									textContent: 'This paragraph cannot be outdented further',
									variant: 'danger',
								});
							}
						} else {
							if($paragraphEl.hasClass('no-indent')){
								window.$vm.emitter.emit('globalToasterOpen',{
									textContent: 'This paragraph cannot be outdented further',
									variant: 'danger',
								});
							} else {
								editor.execCommand('outdent');
							}
						}
					}
				}
			});//e:on:key
			
			function commandDefinition() {
				globalHelpers.specificDefinition.apply(this, arguments);

				this.allowedContent = {
					'p': {
						// Do not add elements, but only text-align style if element is validated by other rule.
						propertiesOnly: true,
						classes: 'no-indent'
					}
				};

				this.contentTransformations = [
                    ['p: splitMarginShorthand']
				];

				this.jobs = {
					'20': {
						refresh: (editor, path ) => {
							let firstBlock = path.block || path.blockLimit;
							
							if (!firstBlock.is($listItem)) {
								let ascendant = firstBlock.getAscendant( $listItem );
								firstBlock = ( ascendant && path.contains( ascendant ) ) || firstBlock;
							}

							if (firstBlock.is($listItem)){
								firstBlock = firstBlock.getParent();
							}

							if (!this.getContext(path)) {
								store.dispatch('paperEdit/ck/toolbarSetButtonState', {
									commandName: 'indentblock',
									editorType: editor.$ckEditorType,
									state: CKEDITOR.TRISTATE_DISABLED,
								});
								store.dispatch('paperEdit/ck/toolbarSetButtonState', {
									commandName: 'outdentblock',
									editorType: editor.$ckEditorType,
									state: CKEDITOR.TRISTATE_DISABLED,
								});
								return CKEDITOR.TRISTATE_DISABLED;

							} else {
								if (this.isIndent) {
									if (firstBlock.hasClass('no-indent')) {
										store.dispatch('paperEdit/ck/toolbarSetButtonState', {
											commandName: 'indentblock',
											editorType: editor.$ckEditorType,
											state: CKEDITOR.TRISTATE_OFF,
										});
										return CKEDITOR.TRISTATE_OFF;
									} else {
										// cursor is inside a paragrah tag, check if this p tag is inside a blockquote
										isInBlockquote = false;

										// loop through all child elements
										_forEach(path.elements, (element)=>{
											if(element.getName() === 'blockquote'){
												isInBlockquote = true;
												return false; 	// stop looping i just need to find one
											}
										});
										if(isInBlockquote){
											// allow a blockquote paragraph to be indented
											store.dispatch('paperEdit/ck/toolbarSetButtonState', {
												commandName: 'indentblock',
												editorType: editor.$ckEditorType,
												state: CKEDITOR.TRISTATE_OFF,
											});
											return CKEDITOR.TRISTATE_OFF;
										} else {
											// regular paragaph not inside a blockquote, disable
											store.dispatch('paperEdit/ck/toolbarSetButtonState', {
												commandName: 'indentblock',
												editorType: editor.$ckEditorType,
												state: CKEDITOR.TRISTATE_DISABLED,
											});
											return CKEDITOR.TRISTATE_DISABLED;
										}
										
									}
								} else if (!firstBlock) {
									store.dispatch('paperEdit/ck/toolbarSetButtonState', {
										commandName: 'indentblock',
										editorType: editor.$ckEditorType,
										state: CKEDITOR.TRISTATE_DISABLED,
									});
									store.dispatch('paperEdit/ck/toolbarSetButtonState', {
										commandName: 'outdentblock',
										editorType: editor.$ckEditorType,
										state: CKEDITOR.TRISTATE_DISABLED,
									});
									return CKEDITOR.TRISTATE_DISABLED;

								} else {
									if (firstBlock.hasClass('no-indent')) {
										store.dispatch('paperEdit/ck/toolbarSetButtonState', {
											commandName: 'outdentblock',
											editorType: editor.$ckEditorType,
											state: CKEDITOR.TRISTATE_DISABLED,
										});
										return CKEDITOR.TRISTATE_DISABLED;
									} else {
										store.dispatch('paperEdit/ck/toolbarSetButtonState', {
											commandName: 'outdentblock',
											editorType: editor.$ckEditorType,
											state: CKEDITOR.TRISTATE_OFF,
										});
										return CKEDITOR.TRISTATE_OFF;
									}
								}
							}
						},

						exec: (editor) => {
							let selection = editor.getSelection(),
								range = selection && selection.getRanges()[ 0 ],
								nearestListBlock;

							// If there's some list in the path, then it will be
							// a full-list indent by increasing or decreasing margin property.
							if ((nearestListBlock = editor.elementPath().contains($list))) {
								indentElement.call(this, nearestListBlock);

								// If no list in the path, use iterator to indent all the possible
								// paragraphs in the range, creating them if necessary.
							} else {
								let iterator = range.createIterator(),
									enterMode = editor.config.enterMode,
									block;

								iterator.enforceRealBlocks = true;
								iterator.enlargeBr = enterMode != CKEDITOR.ENTER_BR;

								while ((block = iterator.getNextParagraph(enterMode == CKEDITOR.ENTER_P ? 'p' : 'div'))) {
									if (!block.isReadOnly()){
										indentElement.call(this, block);
									}
								}
							}

							return true;
						}
					}
				};
			}

			CKEDITOR.tools.extend( commandDefinition.prototype, globalHelpers.specificDefinition.prototype, {
				// Elements that, if in an elementpath, will be handled by this
				// command. They restrict the scope of the plugin.
				context: {p: 1},
			});
		}//e:init

	});//e:plugins.add


	// Generic indentation procedure for indentation of any element
	// either with margin property or config#indentClass.
	function indentElement(element) {
		if (element.getCustomData('indent_processed')){
			return;
		}

		// Determine if we need to move in or out (same call for both buttons)
		if (this.isIndent) {
			// Indent
			if(isInBlockquote){
				element.addClass('blockquote-indent');
			} else {
				element.removeClass('no-indent');
			}
		} else {
			// Unindent
			if(isInBlockquote){
				element.removeClass('blockquote-indent');
			} else {
				element.addClass('no-indent');
			}
		}

		CKEDITOR.dom.element.setMarker( this.database, element, 'indent_processed', true);

		return;
	}

})();